.react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus,
.react-tel-input .flag-dropdown.open .selected-flag {
  background-color: transparent;
}

.react-tel-input .form-control {
  border-radius: 12px;
  transition: .2s;
}

.react-tel-input .form-control:hover {
  box-shadow: 0 0 0 1px #962BED;
}

.react-tel-input .form-control:focus {
  box-shadow: 0 0 0 2px #962BED, 0 0 6px #8627d3;
}

.react-tel-input .invalid-number,
.react-tel-input .invalid-number:hover,
.react-tel-input .invalid-number:focus {
  box-shadow: 0 0 0 2px #c41c1c;
}
