.modal-enter {
  opacity: 0;
  visibility: hidden;
}

.modal-enter-active {
  opacity: 1;
  visibility: visible;
  transition: backdrop-filter 0.2s, opacity 0.2s;  
}

.modal.modal-enter-active .MuiModal-backdrop,
.modal.modal-enter-done .MuiModal-backdrop {
  opacity: 1;
  backdrop-filter: blur(8px);
}

.modal.modal-enter-active .MuiModalDialog-root,
.modal.modal-enter-done .MuiModalDialog-root {
  opacity: 1;
  /* transform: scale(1) !important; */
}

.modal-exit {
  opacity: 1;
  visibility: visible;
}

.modal-exit-active {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s;
}

.modal-exit-done {
  opacity: 0;
  visibility: hidden;
}

.modal .MuiModal-backdrop {
  opacity: 0;
  backdrop-filter: none;
  transition: backdrop-filter 0.2s, opacity 0.2s;
}

.modal .MuiModalDialog-root {
  opacity: 0;
  transition: opacity 0.2s, transform 0.2s;
  /* transform: scale(0.9) !important; */
}
